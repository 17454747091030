import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Layout,
  Spin,
  Typography,
  Table,
  Button,
  Space,
  Form,
  Modal,
  Tag,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EditFilled,
  DeleteFilled
} from '@ant-design/icons';
import notification from '../../Global/Notification';
import Constant from '../../Global/Constant';
import UserRoleModal from './UserRoleModal';
import { queryCache, useMutation, usePaginatedQuery } from 'react-query';
import userRoleService from '../../Services/userRoleService';
import Searchbox from '../../Components/Search';
const { Title } = Typography;

type TableHeaderProp = {
  title: string;
  dataIndex: string;
  key?: string;
  align?: 'left' | 'right' | 'center' | undefined;
  render?: (value: any) => any;
};

const fetchUserRoles = async (
  key: string,
  pageNumber: number,
  searchText: string
) => {
  if (searchText === '') {
    const response = await userRoleService.getUserRoles(
      pageNumber,
      Constant.itemsPerPage
    );
    return { userRoles: response.data.roles, count: response.data.total };
  }
  const response = await userRoleService.getSearchResult(
    searchText,
    pageNumber,
    Constant.itemsPerPage
  );
  return { userRoles: response.data.roles, count: response.data.totalRecords };
};

const createOrUpdateUserRole = async (newRole: any) => {
  if (newRole.id) {
    const response = await userRoleService.updateUserRole(
      newRole.id,
      newRole.modifiedData
    );
    return response.config.data;
  }
  const response = await userRoleService.createUserRole(newRole);
  return response.config.data;
};

const deleteUserRole = async (id: any) => {
  const response: any = await userRoleService.deleteUserRole(id);
  if (
    response &&
    response.response &&
    response.response.data &&
    response.response.data.error
  ) {
    notification.error({
      description: response.response.data.error.message,
      message: 'Error',
    });
    return
  }
  return response.data.message;
};

function UserRole() {
  const [form] = Form.useForm();

  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { resolvedData, status } = usePaginatedQuery(
    ['userRoles', pageNumber, searchText.trim()],
    fetchUserRoles,
    {
      onSuccess: (success: any) => {
        onMutateSuccess('getUserRoles', success);
      },
      onError: (error: any) => {
        onMutateError(error);
      },
    }
  );
  const [createOrUpdateMutate] = useMutation(createOrUpdateUserRole, {
    onSuccess: (success: any) => {
      onMutateSuccess('createOrUpdate', success);
    },
    onError: (error: any) => {
      onMutateError(error);
    },
  });
  const [deleteMutate] = useMutation(deleteUserRole, {
    onSuccess: (success: any) => {
      onMutateSuccess('delete', success);
    },
    onError: (error: any) => {
      onMutateError(error);
    },
  });

  const viewUserRoleModal = (isEditMode: boolean, record?: any) => {
    setEditMode(isEditMode);
    form.resetFields();
    form.setFieldsValue(record || {});
    form.setFields([
      { touched: false, name: 'role' },
      { touched: false, name: 'userAccess' },
    ]);
    setModalVisibility(true);
  };

  const onMutateSuccess = (isFrom: string, success: any) => {
    if (isFrom === 'createOrUpdate' || isFrom === 'delete') {
      queryCache.refetchQueries('userRoles');
      if (isFrom === 'delete') {
        const lastPageNumber = Math.ceil(
          (resolvedData?.count - 1) / Constant.itemsPerPage
        );
        setPageNumber(
          pageNumber < lastPageNumber ? pageNumber : lastPageNumber
        );
      }
      if (isFrom === 'createOrUpdate') {
        setPageNumber(
          editMode
            ? pageNumber
            : Math.ceil((resolvedData?.count + 1) / Constant.itemsPerPage)
        );
      }
      setModalLoading(false);
      setModalVisibility(false);
    }
  };

  const onMutateError = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      notification.error({
        description: error.response.data.error.message,
        message: 'Error',
      });
    }
  };

  const viewDeleteConfirmModal = (id: string) => {
    Modal.confirm({
      title: ' ',
      content: Constant.deleteUserRoleContent,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteMutate(id);
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const tableColumns = [
    {
      title: '#',
      key: 'key',
      render: (record: any) => (
        <span>
          {resolvedData?.userRoles.length > 10
            ? resolvedData?.userRoles?.indexOf(record) + 1
            : resolvedData?.userRoles?.indexOf(record) +
              1 +
              Constant.itemsPerPage * (pageNumber - 1)}
        </span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      align: 'left',
    },
    {
      title: 'Apps',
      dataIndex: 'userAccess',
      key: 'apps',
      render: (userAccess: Array<number>) => (
        <span>
          {userAccess.includes(Constant.userAccess.appAccess) ? (
            <Tag color='processing'>Yes</Tag>
          ) : (
            <Tag color='default'>No</Tag>
          )}
        </span>
      ),
    },
    {
      title: 'Kite API',
      dataIndex: 'userAccess',
      key: 'kiteApi',
      render: (userAccess: Array<number>) => (
        <span>
          {userAccess.includes(Constant.userAccess.kiteApi) ? (
            <Tag color='processing'>Yes</Tag>
          ) : (
            <Tag color='default'>No</Tag>
          )}
        </span>
      ),
    },
    {
      title: 'AD Settings',
      dataIndex: 'userAccess',
      key: 'ADSettings',
      render: (userAccess: Array<number>) => (
        <span>
          {userAccess.includes(Constant.userAccess.ADSettings) ? (
            <Tag color='processing'>Yes</Tag>
          ) : (
            <Tag color='default'>No</Tag>
          )}
        </span>
      ),
    },
    {
      title: 'Users',
      dataIndex: 'userAccess',
      key: 'users',
      render: (userAccess: Array<number>) => (
        <span>
          {userAccess.includes(Constant.userAccess.users) ? (
            <Tag color='processing'>Yes</Tag>
          ) : (
            <Tag color='default'>No</Tag>
          )}
        </span>
      ),
    },
    {
        title: 'User Roles',
        dataIndex: 'userAccess',
        key: 'userRole',
        render: (userAccess: Array<number>) => (
          <span>
            {userAccess.includes(Constant.userAccess.userRoles) ? (
              <Tag color='processing'>Yes</Tag>
            ) : (
              <Tag color='default'>No</Tag>
            )}
          </span>
        ),
      },
      {
        key: 'action',
        render: (text: any, record: any) => (
          <Space size="large">
          <EditFilled onClick={() => viewUserRoleModal(true, record)} />
          <DeleteFilled onClick={() => viewDeleteConfirmModal(record.id)} />
          </Space>
        ),
      },
  ] as Array<TableHeaderProp>;

  const saveData = () => {
    setModalLoading(false);
    form.validateFields().then((value) => {
      if (editMode) {
        const modifiedData = form.getFieldsValue(
          Object.keys(value).filter((x: string) => form.isFieldTouched(x))
        );
        if (Object.keys(modifiedData).length > 0) {
          value.modifiedData = modifiedData;
          createOrUpdateMutate(value);
        } else {
          setModalLoading(false);
          setModalVisibility(false);
        }
      } else {
        createOrUpdateMutate(value);
      }
    });
  };

  const onSearchText = (currentSearchText: string) => {
    if (searchText !== currentSearchText) {
      setSearchText(currentSearchText);
      setPageNumber(Constant.defaultPageNumber);
    }
  };

  const handleSearchTextChange = (value: string) => {
    if (value.length === 0) {
      onSearchText('');
    }
  };

  return (
    <Layout.Content>
      <Spin spinning={status === 'loading'}>
        <div className='container custom-container'>
          <div className='d-flex sm-flex-direction-column'>
            <div className='flex-fill content-title mb-3'>
              <Title level={4}> User Roles</Title>
              <div className='title-line'></div>
            </div>
            <div className='flex-auto'>
              <div className='top-bar d-flex mb-3'>
                <div className='d-inline-block mr-2 pr-2 border-right'>
                  <Searchbox
                    onSearch={onSearchText}
                    onChange={handleSearchTextChange}
                  />
                </div>
                <div className='d-inline-block add-btn-style'>
                  <Button
                    className='sm'
                    type='primary'
                    onClick={() => viewUserRoleModal(false)}
                    disabled={status === 'loading'}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='position-relative userrole-wrapper sso-table'>
            <Table
              scroll={{ x: 600 }}
              className={`pb-5 bg-white ${
                resolvedData?.count <= Constant.itemsPerPage
                  ? 'table-padding'
                  : ''
              }`}
              columns={tableColumns}
              rowKey={(record) => record.id}
              pagination={
                resolvedData?.count > Constant.itemsPerPage && {
                  pageSize: Constant.itemsPerPage,
                  position: ['bottomCenter'],
                  total: resolvedData?.count,
                  current: pageNumber,
                  onChange: (currentPageNumber: number) => {
                    setPageNumber(currentPageNumber);
                  },
                  showSizeChanger: false,
                }
              }
              dataSource={resolvedData?.userRoles}
            />
          </div>
          <UserRoleModal
            form={form}
            modalVisibility={modalVisibility}
            editMode={editMode}
            onCancel={() => setModalVisibility(false)}
            onOk={saveData}
            modalLoading={modalLoading}
          />
        </div>
      </Spin>
    </Layout.Content>
  );
}

export default observer(UserRole);
