import React, { useState, useEffect } from 'react';
import {
  Layout,
  Typography,
  Button,
  Form,
  Input,
  Spin,
} from 'antd';

import notification from '../../Global/Notification';
import settingsStore from '../../Store/settingsStore';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
function Settings() {

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();



  const setFormValues = ():void => {   
    form.resetFields();
    form.setFieldsValue(settingsStore.settings || {});
    form.setFields([
      { touched: false, name: 'id' },
      { touched: false, name: 'url' },
      { touched: false, name: 'base_dn' },
      { touched: false, name: 'domain' },
      { touched: false, name: 'username' },
      { touched: false, name: 'password' },
    ]);
  };

  const getSettings = (): void => {
    setLoading(true);
    settingsStore.getSettingsList((error: string | null) => {
      setLoading(false);
      if (error) {
        notification.error({
          description: error,
          message: 'Error',
        });
      } else {
        if (settingsStore.total !== 0) {    
          setFormValues();
          setEditMode(true);
        }
      }
    });
  }

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  const saveDataAction = (err: string) => {
    setLoading(false);
    if (err) {
      notification.error({
        description: err,
        message: 'Error',
      });
    } else {
      getSettings();
    }
  };

  const saveSettingsData = (): void => {
    form
    .validateFields()
    .then((values) => {
      setLoading(true);
      const {
        url, base_dn, domain, username, password
      } = values;
      if (!values.id) {
        settingsStore.saveData(
          url, 
          base_dn, 
          domain, 
          username, 
          password, 
          (err: any) => {
          saveDataAction(err);
        });
        
      } else {
        const updatedValues = form.getFieldsValue(Object.keys(values)       
          .filter((x: string) => form.isFieldTouched(x)));
        if (Object.keys(updatedValues).length) {
          settingsStore.updateData(values.id, updatedValues, (err: any) => {
            saveDataAction(err);
          });
        } else {
          setLoading(false);
          getSettings();
        }
      }

    })
    .catch((err: Error) => {
      if (err.message) {
        notification.error({
          description: err.message,
          message: 'Error',
        });
      }
    });
  }

  const handleSave = (): void => {
    saveSettingsData();
  };

  const handleUpdate = (): void => {    
    setEditMode(false);
  };

  const handleCancel = (): void => {
    setFormValues();
    setEditMode(true);
  }
  

  return (
    <Layout.Content>
      <Spin spinning={loading}>
        <div className='d-flex sm-flex-direction-column '>
          <div className='flex-fill content-title mb-3'>
            <div className="container">
            <Typography.Title level={4}>
               Settings
            </Typography.Title>
            </div>
          </div>
        </div>
        <div className="container card py-5 sso-setting">
          <Form {...layout} name="nest-messages" className="my-5" form={form}>
            <Form.Item name="id" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="url" label="URL" rules={
              [
                {
                  pattern: /(?:ldap|http)s?:\/\/(?:(?!.*\d[\/?:])[a-z0-9\-._~%]+|(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\[[a-z0-9\-._~%!$&'()*+,;=:]+\])(?::\d+)?(?:[\/?][\-A-Z0-9+&@#\/%?=~_|$!:,.;]*)?/i,
                  required: true,
                  message:'URL is not a valid'                  
                },
              ]
              }>
            <Input disabled={editMode}/>
            </Form.Item>
            <Form.Item 
              name="base_dn" 
              label="Base DN"
              rules={
                [
                  {
                    required: true,
                    message:'Base DN required'
                  },
                  {
                    whitespace: true,                  
                  },
                ]
              }>
              <Input disabled={editMode}/>
            </Form.Item>
            <Form.Item name="domain" label="Domain" rules={
                [{
                  required: true,
                  message:'Domain required'
                }]
              } >
              <Input disabled={editMode}/>
            </Form.Item>
            <Form.Item name="username" label="Username" rules={
                [{
                  required: true,
                  message:'Username required'
                }]
              }>
              <Input disabled={editMode}/>
            </Form.Item>
            <Form.Item 
              name="password" 
              label="Password" 
              rules={
                [{
                  required: true,
                  message:'Password required'
                }]
              }
            >
              <Input.Password disabled={editMode}/>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              {

                settingsStore.total === 0 ? 
                <Button
                htmlType="submit"
                type="primary"
                loading={loading}                      
                onClick={handleSave}                      
              >
                Save
              </Button>

              :
                editMode
                ? (

                  <Button
                  type="primary"
                  htmlType="button"
                  onClick={handleUpdate}
                >
                  Edit
                </Button>
                 
                ) 
              :              
              <>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}                      
                onClick={handleSave}                      
              >
                Save
              </Button>

              <Button
                className="add-btn-style"
                type="primary"
                htmlType="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
              }
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Layout.Content>
  );
}

export default Settings;
