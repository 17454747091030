import React, { useState, useEffect } from 'react';
import {
  Layout,
  Typography,
  Table,
  Button,
  Input,
  Space,
  Spin,
  Form,
  Modal,
  message,
} from 'antd';
import { DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import notification from '../../Global/Notification';
import  Constant  from '../../Global/Constant';
import kiteApiStore from '../../Store/kiteapiStore';
import AddKiteApiModal from './AddKiteApiModal';

const { Search } = Input;

function KiteApi() {
  const [pageNumber, setPageNumber] = useState(1);
  const [listLoading, setListLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isModalVisible, setModalVisibility] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const viewModal = (isEditMode:boolean, record?: any) => {
    setEditMode(isEditMode);
    form.resetFields();
    form.setFieldsValue(record || {});
    form.setFields([
      { touched: false, name: 'id' },
      { touched: false, name: 'appName' },
      { touched: false, name: 'domain' },
      { touched: false, name: 'apiKey' },
      { touched: false, name: 'scope' },
      { touched: false, name: 'active' },
    ]);
    setModalVisibility(true);
  };

  const getKiteApis = ( currentPageNumber: number, searchValue?: string) => {
    setListLoading(true);
    kiteApiStore.getKiteApiList((error: string) => {
      setListLoading(false);
      if (error) {
        notification.error({
          description: error,
          message: 'Error',
        });
      } else {
        setPageNumber(currentPageNumber);
      }
    }, currentPageNumber, Constant.itemsPerPage, searchValue,);
  };

  useEffect(() => {
    getKiteApis(pageNumber);
    // eslint-disable-next-line
  }, []);

  const onPageChange = (currentPageNo: number) => {
    getKiteApis(currentPageNo, searchText);
  };
  const saveDataAction = (err: string) => {
    setLoading(false);
    if (err) {
      notification.error({
        description: err,
        message: 'Error',
      });
    } else {
      setModalVisibility(false);
      getKiteApis(
        editMode ? pageNumber : Math.ceil((kiteApiStore.total + 1) / Constant.itemsPerPage),
        searchText,
      );
    }
  };

  const generateApiKey = () => {
    const id = form.getFieldValue('id');         
    kiteApiStore.updateApiKey(id, (err: any, data?: string) => {
      if (err) {
        notification.error({
          description: err,
          message: 'Error',
        });
      } else {
        data && form.setFieldsValue({'apiKey': data});
      }
    });
    message.info('API Key Changed!');
  }

  const saveApiData = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const {
          appName, domain, scope,
        } = values;
        if (!values.id) {
          kiteApiStore.addApi(appName, domain, scope, (err: any, data?: any) => {
            saveDataAction(err);
          });
          
        } else {
          const updatedValues = form.getFieldsValue(Object.keys(values)
            .filter((x: string) => form.isFieldTouched(x)));
          if (Object.keys(updatedValues).length) {
            kiteApiStore.updateApi(values.id, updatedValues, (err: any) => {
              saveDataAction(err);
            });
          } else {
            setLoading(false);
            setModalVisibility(false);
          }
        }
      })
      .catch(() => {});
  };

  const deleteApi = (id:string) => {
    setLoading(true);
    kiteApiStore.deleteApp(id, (error: string) => {
      setLoading(false);
      if (error) {
        notification.error({
          description: error,
          message: 'Error',
        });
      } else {
        const lastPageNumber = Math.ceil((kiteApiStore.total - 1) / Constant.itemsPerPage);
        getKiteApis(
          pageNumber < lastPageNumber ? pageNumber : lastPageNumber,
          searchText,
        );
      }
    });
  }

  const confirmDeletion = (id : string) => {
    Modal.confirm({
      title: Constant.deleteModalTitle,
      icon: <ExclamationCircleOutlined />,
      content: Constant.deleteModalContent,
      okText: 'Yes',
      cancelText: 'No',
      onOk() { deleteApi(id); },
    });
  };

  const tableColumns = [
    {
      title: '#',
      key: 'key',
      render: (record: any) => (
        <span>
          { (
            kiteApiStore.kiteapi.indexOf(record) + 1)
            + (Constant.itemsPerPage * (pageNumber - 1)
            ) }
        </span>
      ),
    },
    {
      title: 'App Name',
      dataIndex: 'appName',
      key: 'appName',
    },
    {
      title: 'Server IP',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="large">
          <EditFilled onClick={() => viewModal(true, record)} />
          <DeleteFilled onClick={() => confirmDeletion(record.id)} />
        </Space>
      ),
    },
  ] as any;

  const onSearchText = (currentSearchText: string) => {
    if (currentSearchText !== searchText) {
      setSearchText(currentSearchText);
    }
    getKiteApis(Constant.defaultPageNumber, currentSearchText);
  };

  const handleSearchTextChange = (value: string) => {
    if (value.length === 0) {
      onSearchText('');
    }
  };

  const cancelModal = () => {
    setModalVisibility(false);
    const lastPageNumber = Math.ceil((kiteApiStore.total - 1) / Constant.itemsPerPage);
    getKiteApis(
      pageNumber < lastPageNumber ? pageNumber : lastPageNumber,
      searchText,
    );
  };

  return (
    <Layout.Content>
      <Spin spinning={listLoading}>
<div className="container">
        <div className='d-flex flex-column flex-sm-row mb-3'>
          <div className='flex-fill content-title mb-3'>
            <Typography.Title level={4}>
                Kite Api List
            </Typography.Title>
            <div className='title-line'></div>
          </div>
          <div className='flex-auto'>
            <div className='top-bar d-flex mb-3'>
              <div className='d-inline-block mr-2 pr-2 border-right'>
                <Search
                    placeholder="Search"
                    className="ml-2"
                    onSearch={(value) => onSearchText(value)}
                    onChange={(e) => handleSearchTextChange(e.target.value)}
                    style={{ width: 343 }}
                    allowClear
                />
              </div>
              <div className='d-inline-block add-btn-style'>
                <Button
                  className='sm'
                  type='primary'
                  onClick={() => viewModal(false)}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative sso-table">
          <Table
            className={`bg-white ${kiteApiStore.total <= Constant.itemsPerPage ? 'table-padding' : ''}`}
            columns={tableColumns}
            dataSource={kiteApiStore.kiteapi}
            pagination={kiteApiStore.total > Constant.itemsPerPage
              && {
                pageSize: Constant.itemsPerPage,
                position: ['bottomCenter'],
                total: kiteApiStore.total,
                current: pageNumber,
                onChange: onPageChange,
                showSizeChanger: false,
              }}
          />
        </div>
        <AddKiteApiModal
          editMode={editMode}
          form={form}
          isModalVisible={isModalVisible}
          loading={loading}
          onSave={saveApiData}
          onCancel={() => cancelModal()}
          generateApiKey={generateApiKey}
        />
        </div>
      </Spin>
    </Layout.Content>
  );
}

export default KiteApi;
