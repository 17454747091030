import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, Row, Col, Typography, message } from 'antd';
import serviceMonitoringService from '../../../Services/serviceMonitoringService';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Utility from '../../../Global/Utility';
import { CopyOutlined }  from '@ant-design/icons';

dayjs.extend(customParseFormat);
const { Option } = Select;


interface AddServiceModalProps {
    isOpen: boolean;
    onClose: () => void;
    record?: any;
    isEditMode: boolean;
    appNamesArray: any;
    getAllServiceMonitoringDatas: () => void;
    form: any;
    initialValues: any;
    setInitialValues: any;
    editId: any;
}

const AddServiceModal: React.FC<AddServiceModalProps> = ({ isOpen, onClose, record, isEditMode, appNamesArray, getAllServiceMonitoringDatas, form, initialValues, setInitialValues, editId }) => {

    const handleOnChangeOfFieldValues = (e: any, name: any) => {
        const { value } = e.target;
        setInitialValues((prevValues: any) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    useEffect(() => {
        if (isEditMode && record) {
            form.setFieldsValue(record);
        } else {
            form.resetFields();
        }
    }, [isEditMode, record]);

    const handleOk = async () => {
            if(form.getFieldValue('appName') && form.getFieldValue('moduleName') && (initialValues?.minute || initialValues?.hour || initialValues?.month || initialValues?.dayWeek || initialValues?.dayMonth)){
                if(isEditMode){
                    await serviceMonitoringService.updateServiceMonitoringDetails(editId, {appName: form.getFieldValue('appName'), moduleName: form.getFieldValue('moduleName'),
                        frequencyMin: initialValues.minute, frequencyHour: initialValues.hour, frequencyDayMonth: initialValues.dayMonth, frequencyMonth: initialValues.month, frequencyDayWeek: initialValues.dayWeek}) 
                }
                else{
                    await serviceMonitoringService.addServiceMonitoringDetails({appName: form.getFieldValue('appName'), moduleName: form.getFieldValue('moduleName'),
                        frequencyMin: initialValues.minute, frequencyHour: initialValues.hour, frequencyDayMonth: initialValues.dayMonth, frequencyMonth: initialValues.month, frequencyDayWeek: initialValues.dayWeek}) 
                }
                    getAllServiceMonitoringDatas();
                onClose();
            }
    };

    const copyToClipboard = (moduleId: any) => {
        navigator?.clipboard?.writeText(moduleId);
        message.info('Copied Module Id!');
      }

    return (
        <Modal
            title={isEditMode ? 'Edit Service Monitoring Details' : 'Add Service Monitoring Details'}
            visible={isOpen}
            onOk={handleOk}
            onCancel={onClose}
            centered
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {isEditMode ? 'Update' : 'Submit'}
                </Button>,
            ]}
            closable={false}
            maskClosable={false}
            keyboard={false}
        >
            <Form
                form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                labelAlign="left"
                colon={false}
            >
                <Form.Item name="id" noStyle>
                    <Input type="hidden" />
                </Form.Item>

                <Row>
                <Col span={24}> 
            {isEditMode &&
            <Form.Item
              className="mb-2"
              label="Module Id"
              name="moduleId"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                },
                {
                  whitespace: true
                },
              ]}
            >
              <Input 
                bordered={false} 
                disabled={true}
                className="labelText" 
                suffix={
                  <CopyOutlined 
                    onClick={()=> copyToClipboard(form.getFieldValue('moduleId'))}
                  /> 
                }
              /> 
              
            </Form.Item> 
             
            }
          </Col>
                            <Col span={24}>
                    <Form.Item
                        className="mb-2"
                        name="appName"
                        label="App Name"
                        rules={ 
                            [{ required: true, message: 'Please select an app name!' }]
                    }
                    >
                        <Select 
                        showSearch 
                        placeholder="Select App Name" 
                        suffixIcon={null}
                        optionFilterProp="children" 
                        >
                          {appNamesArray.map((data: any) => (
                                         <Option value={data?.id}>{data?.appName}</Option>
                                    ))}
                        </Select>
                        {/* )} */}
                    </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className="mb-2"
                            label="Module Name"
                            name="moduleName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Module Name is required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter Module Name" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className="mb-0"
                            label="Frequency"
                            rules={[
                                {
                                    required: true,
                                    message: 'Frequency is required',
                                },
                            ]}
                        >
                            <Row gutter={2}>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        name="minute"
                                    >
                                        <Input placeholder="Min" style={{
                                            textAlign: 'center',
                                        }} value={initialValues.minute} onChange={(e: any) => handleOnChangeOfFieldValues(e, 'minute')} pattern="[0-9\*\-/]" maxLength={10}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/[^0-9\*\-/]/g, '');
                                            handleOnChangeOfFieldValues(e, 'minute'); 
                                          }}
                                          title="Only numbers, *, -, / are allowed"/>
                                        <Typography.Text type="secondary">Minute</Typography.Text>
                                    </Form.Item>

                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        name="hour"
                                    >
                                        <Input placeholder="Hrs" style={{
                                            textAlign: 'center',
                                        }} value={initialValues.hour} onChange={(e: any) => handleOnChangeOfFieldValues(e, 'hour')} pattern="[0-9\*\-/]" maxLength={10}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/[^0-9\*\-/]/g, '');
                                            handleOnChangeOfFieldValues(e, 'hour'); 
                                          }}
                                          title="Only numbers, *, -, / are allowed"/>
                                        <Typography.Text type="secondary">Hour</Typography.Text>
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        name="Month"
                                    >
                                        <Input placeholder="Day" style={{
                                            textAlign: 'center',
                                        }} value={initialValues.month} onChange={(e: any) => handleOnChangeOfFieldValues(e, 'month')} pattern="[0-9\*\-/]" maxLength={10}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/[^0-9\*\-/]/g, '');
                                            handleOnChangeOfFieldValues(e, 'month'); 
                                          }}
                                          title="Only numbers, *, -, / are allowed"/>
                                        <Typography.Text type="secondary">Day (Month)</Typography.Text>
                                    </Form.Item>

                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        name="dayMonth"
                                    >
                                        <Input placeholder="Month" style={{
                                            textAlign: 'center',
                                        }} value={initialValues.dayMonth} onChange={(e: any) => handleOnChangeOfFieldValues(e, 'dayMonth')} pattern="[0-9\*\-/]" maxLength={10}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/[^0-9\*\-/]/g, '');
                                            handleOnChangeOfFieldValues(e, 'dayMonth'); 
                                          }}
                                          title="Only numbers, *, -, / are allowed"/>
                                        <Typography.Text type="secondary">Month</Typography.Text>
                                    </Form.Item>

                                </Col>
                                <Col span={4} style={{ textAlign: 'center' }}>
                                    <Form.Item
                                        name="dayWeek"
                                    >
                                        <Input placeholder="Week" style={{
                                            textAlign: 'center',
                                        }} value={initialValues.dayWeek} onChange={(e: any) => handleOnChangeOfFieldValues(e, 'dayWeek')} pattern="[0-9\*\-/]" maxLength={10}
                                        onInput={(e: any) => {
                                            e.target.value = e.target.value.replace(/[^0-9\*\-/]/g, '');
                                            handleOnChangeOfFieldValues(e, 'dayWeek'); 
                                          }}
                                          title="Only numbers, *, -, / are allowed"/>
                                        <Typography.Text type="secondary">Day (Week)</Typography.Text>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddServiceModal;
