import React, { useEffect, useState } from 'react';
import {
  Layout,
  Typography,
  Button,
  Modal,
  Input,
  Space,
  Spin, 
  Tag,
  Card,
  Col,
  Row,
  Pagination,
  Form,
  Select,
} from 'antd';
import { DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import AddServiceModal from './AddServiceModal';
import Text from 'antd/lib/typography/Text';
import { Footer } from 'antd/lib/layout/layout';
import serviceMonitoringService from '../../Services/serviceMonitoringService';
import Constant from '../../Global/Constant';
import moment from 'moment';
import authStore from '../../Store/authStore';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
const { Option } = Select;

const { Search } = Input;

function ServiceMonitoring() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [listLoading, setListLoading] = useState(false);
  const [appNamesArray, setAppNamesArray] = useState<any>([]);
  const [serviceMonitoringDatas, setServiceMonitoringDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]); 
  const [editId, setEditId] = useState();
  const [initialValues, setInitialValues] = useState({
    minute: '',
    hour: '',
    month: '',
    dayWeek: '',
    dayMonth: ''
})
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [statusFilter, setStatusFilter] = useState<number | null>(null);

  const menu = (data: any) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(data)}>
        <EditFilled className='pe-2'/> Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => confirmDeletion(data.id)}>
       <DeleteFilled className='pe-2' /> Delete
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    getAllServiceMonitoringDatas();
    getAllApps();
  },[])

  useEffect(() => {
    const interval = setInterval(() => {
      getAllServiceMonitoringDatas();
    }, 10000); 
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    filterData();
  }, [searchValue, statusFilter]);

  const getAllApps = async () => {
    const response = await serviceMonitoringService.getApps();
    setAppNamesArray(response.data.appNames);
  }

  const getAllServiceMonitoringDatas = async () => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await serviceMonitoringService.getServiceMonitoringDatas(clientTimeZone);
    const sortedArray = response?.data?.getServiceMonitoringDatas.sort((a: any, b: any) => a.nameOfApp.localeCompare(b.nameOfApp));
    setFilteredData(sortedArray);
    setServiceMonitoringDatas(response?.data?.getServiceMonitoringDatas);
  }

  const handleAdd = () => {
    setInitialValues({
      minute: '',
      hour: '',
      month: '',
      dayWeek: '',
      dayMonth: ''
    })
    form.setFieldsValue({
      appName: '',
      moduleName: '',
      moduleId: ''
    });
    setSelectedRecord(null);
    setIsEditMode(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleEdit = (data: any) => {
    setEditId(data.id);
    setIsEditMode(true);
    setIsModalOpen(true);
    setTimeout(() => {
      form.setFieldsValue({
        appName: data?.appName,
        moduleName: data?.moduleName,
        moduleId: data?.moduleId
      });
      setInitialValues({
        minute: data?.frequencyMin,
        hour: data?.frequencyHour,
        month: data?.frequencyMonth,
        dayWeek: data?.frequencyDayWeek,
        dayMonth: data?.frequencyDayMonth
      })
  }, 0); 
  }

  const handleDelete = async (id: any) => {
    const response = await serviceMonitoringService.deleteServiceMonitoring(id);
    if(response){
      getAllServiceMonitoringDatas();
    }
  }

  const confirmDeletion = (id : string) => {
    Modal.confirm({
      title: Constant.deleteModalTitle,
      icon: <ExclamationCircleOutlined />,
      content: Constant.deleteServiceContent,
      okText: 'Yes',
      cancelText: 'No',
      onOk() { handleDelete(id); },
    });
  };

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value);
  };  

  const onDropdownChange = (value: any) => {
    setStatusFilter(value);
  };

  const filterData = () => {
    let filteredDataValue = serviceMonitoringDatas;
  
    if (searchValue) {
      filteredDataValue = filteredDataValue.filter((item: any) =>
        item.nameOfApp.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.moduleName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  
    if (statusFilter) {
      filteredDataValue = filteredDataValue.filter((data: any) =>
        statusFilter === 1 ? (!data?.lastFailedSyncDate && data.nextSync) : (data?.lastFailedSyncDate && data.nextSync)
      );
    }
  
    setFilteredData(filteredDataValue);
    setCurrentPage(1);
  };

  return (
    <Layout.Content>
      <Spin spinning={listLoading}>
        <div className="container responsive-container sso-service">
          <div className="d-flex flex-column flex-sm-row mb-3">
            <div className="flex-fill content-title mb-3">
              <Typography.Title level={4}>Service Monitoring List</Typography.Title>
              <div className="title-line"></div>
            </div>
            <div className="flex-auto">
              <div className="top-bar d-flex mb-3 flex-wrap sso-service-search">
                <div className="d-inline-block mr-2 pr-2 border-right service-search">
                  <Search
                    placeholder="Search"
                    className="pe-2"
                    allowClear
                    onChange={(e: any) => onSearchChange(e)}
                  />
                </div>
                <div className="d-inline-block mr-2 pr-2 border-right">
                <Select
                className="ml-2"
                placeholder="Select Status"
                optionLabelProp="label"
                onChange={onDropdownChange}
                allowClear
              >
                <Option value={1} label='Success'>Success</Option>
                <Option value={2} label='Failed'>Failed</Option>
              </Select>
              </div>
                {authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoringAction) &&
                <div className="d-inline-block add-btn-style">
                  <Button className="sm" type="primary" onClick={handleAdd}>
                    Add
                  </Button>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="position-relative">
            <Row gutter={[16, 16]}>
              {filteredData.map((data: any) => (
                  <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                  <Card style={{height:'100%'}}
                    className={data.lastFailedSyncDate? "responsive-card red-card": "responsive-card"}
                    bordered={false}
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>{(appNamesArray.filter((value: any) =>value.id == data?.appName)[0])?.appName}</span>
                        {authStore?.currentUser?.userAccess.includes(Constant.userAccess.serviceMonitoringAction) &&
                          <Dropdown overlay={menu(data)} trigger={['click']} placement='bottomRight' className='sso-card'>
                            <MoreOutlined style={{ fontSize: 20, cursor: 'pointer' }}/>
                          </Dropdown>
                        }
                      </div>
                    }
                  >
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <Text className='service-label'>
                      Module Name
                      </Text>
                      <Text className='service-detail'>{data?.moduleName}</Text>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <Text className='service-label'>
                      Scheduled Cron
                      </Text>
                      <Text className='service-detail'>{data?.cronSchedule}</Text>
                    </div>
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <Text className='service-label' >
                        Last Success Sync
                      </Text>
                      {data.lastSync && <Text className={data.lastFailedSyncDate? 'service-detail' : 'sso-monitoring-cyan'}>{data.lastSync ? moment(data.lastSync).format("MMMM D, h:mm A dddd") : ""}</Text>}
                    </div>
                    {data?.lastFailedSyncDate && <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <Text className='service-label' >
                        Last Failed Sync
                      </Text>
                      {data?.lastFailedSyncDate ? (
                        (
                          <Text style={{ color: '#CF1322' }} className='sso-monitoring-red'>
                            {moment(data.lastFailedSyncDate).format("MMMM D, h:mm A dddd")}
                          </Text>
                        )
                      ) : null}
                    </div>}
                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <Text className='service-label' >
                        Next Sync
                      </Text>
                      {(data.nextSync && data.lastSync) && <Text className='service-detail '>{moment(data.nextSync).format("MMMM D, h:mm A dddd")}</Text>}
                    </div>
                  </Card>
                </Col>
              ))}
              
            </Row>
          </div>
          <Footer style={{ textAlign: 'center' }}>
            {/* {filteredData.length > pageSize && (
              <Pagination
                current={currentPage}
                total={filteredData.length}
                pageSize={pageSize}
                onChange={setCurrentPage}
              />
            )} */}
          </Footer>
          <AddServiceModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            isEditMode={isEditMode}
            record={selectedRecord}
            appNamesArray={appNamesArray}
            getAllServiceMonitoringDatas={getAllServiceMonitoringDatas}
            form={form}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            editId={editId}
          />
        </div>
      </Spin>
    </Layout.Content>
  );
}

export default ServiceMonitoring;
