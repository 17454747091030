import api from './api';

class serviceMonitoringService { 

    getApps = async () => {
        try {
          const response = await api.get(`/servicemonitoring/getApps`);
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(error);
        }
      };  
      
      addServiceMonitoringDetails = async (data: any): Promise<any> => {
        return new Promise((resolve, reject) => {
          try {
            api
              .post('/servicemonitoring/addServiceMonitoringDetails', data)
              .then((response) => {
                return resolve(response);
              })
              .catch((err) => {
                return resolve(err);
              });
          } catch (error) {
            return reject(error);
          }
        });
      };

    getServiceMonitoringDatas = async (clientTimeZone: any) => {
        try {
          const response = await api.get(`/servicemonitoring/getServiceMonitoringDatas?clientTimeZone=${clientTimeZone}`);
          return Promise.resolve(response);
        } catch (error) {
          return Promise.reject(error);
        }
      }; 
    
    updateServiceMonitoringDetails = async (id: any, data:any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.put(`/servicemonitoring/updateServiceMonitoringDetails?id=${id}`, data)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
    };

    deleteServiceMonitoring = async (id: any) => {
      return new Promise(async (resolve, reject)=>{
        try {
          api.delete(`/servicemonitoring/deleteServiceMonitoringDatas?id=${id}`)
          .then((response)=>{
            return resolve(response);
          })
          .catch((err)=>{
            return resolve(err)
          })
        } catch (error) {
          return reject(error);
        }
      })
    };

}
export default new serviceMonitoringService();